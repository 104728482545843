.form:global(.ui.form) {
  margin: 0 20px;
}

.help {
  position: absolute;
  right: 10px;
  margin-top: 15px;
}

.latinSwitcher {
  margin-bottom: 20px;
}

.resetButton:global(.ui.button) {
  margin-top: 1em;
}

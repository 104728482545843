:root {
  --primary-color-100: #00a2a9;
  --primary-color-200: #009096;
  --primary-color-300: #007d83;
  --primary-color-400: #007076;
  --primary-color-500: #006268;
  --primary-color-600: #00565b;
  --primary-color-700: #004a4d;
  --primary-color-800: #003a3d;
  --primary-color-900: #002a2c;
  --max-width-right-column: 800px;
  --fluid-button-padding: clamp(5px, 2vw, 30px);
  --fluid-layers-width: clamp(210px, 15vw, 200px);
}

.Toastify__toast-icon {
  display: none !important;
}
.Toastify__toast--info {
  background: #ccc !important;
}
.Toastify__toast--success {
  color: white !important;
  background: var(--primary-color-500) !important;
}
.Toastify__progress-bar--success {
  background: var(--primary-color-100) !important;
}

@media (min-width: 481px) {
  .Toastify__toast--info,
  .Toastify__toast--success {
    border-radius: 5px;
  }
}

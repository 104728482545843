.button:global(.ui.button) {
  background-color: #ccc;
  color: white;
}
.button:global(.ui.button:hover) {
  background-color: #ddd;
  color: white;
}

.button:global(.ui.active.button) {
  background-color: var(--primary-color-500);
  color: white;
}

.button:global(.ui.active.button:hover) {
  background-color: var(--primary-color-200);
  color: white;
}

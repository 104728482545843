.dropdown:global(.ui.button.dropdown) {
  color: white;
  background-color: var(--primary-color-500);
}
.dropdown:global(.ui.button.dropdown):hover {
  color: white;
  background-color: var(--primary-color-200);
}

.dropdown:global(.ui.selection.dropdown) {
  color: var(--primary-color-500);
  border-color: var(--primary-color-500);
  border-width: 2px;
}
.dropdown:global(.ui.selection.dropdown:hover) {
  border-color: var(--primary-color-200);
}

.field label {
  color: var(--primary-color-500) !important;
  text-align: left;
}

.field label h5 {
  margin-bottom: 4px !important;
}

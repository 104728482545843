.form:global(.ui.form) {
  margin: 1em 0;
  padding: 1em;
  box-shadow: 0 -20px 20px -20px #777;
}

.form :global(label) {
  font-size: 18px !important;
}

.help {
  position: absolute;
  right: 15px;
  margin-top: -35px;
}

.listitem {
  display: flex !important;
}

.forestTypeButton {
  display: grid !important;
  grid-template-columns: auto auto auto;
  text-align: left !important;
  gap: 5px;
}

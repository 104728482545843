.field input {
  color: var(--primary-color-500) !important;
  border-color: var(--primary-color-500) !important;
  border-width: 2px !important;
}
.input input:hover {
  border-color: var(--primary-color-200) !important;
}

.field label {
  color: var(--primary-color-500) !important;
}

.tabFooter {
  display: flex;
  justify-content: space-between;
  padding: 20px calc(1em + 1vw);
}

.cantonalForestTypesWrapper {
  background-color: var(--primary-color-400);
  padding: calc(1em + 1vw);
}

.cantonalForestTypeButton:global(.ui.basic.active.button) {
  max-height: 100%;
  color: white !important;
  background-color: var(--primary-color-500) !important;
  border: 1px solid white;
}

.cantonalForestTypeButton:global(.ui.basic.active.button):hover {
  background-color: var(--primary-color-200) !important;
}

.cantonalForestTypeLabel:global(.ui.inverted.header) {
  margin: 0;
}

.cantonalForestType {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100;
}

.exportButton {
  margin-left: auto !important;
}

.exportButton:not(:hover) {
  background-color: var(--primary-color-700) !important;
}

.exportButton:hover:not(:disabled) {
  background-color: var(--primary-color-200) !important;
}

.line {
  stroke: #333;
  fill: none;
}

.thinLine {
  stroke: #333;
  fill: none;
  stroke-width: 0.5;
}
.dashedLine {
  fill: none;
  stroke-dasharray: 10, 10;
}
.greyLine {
  fill: none;
  stroke: #999;
}
.bgLine {
  fill: none;
  fill-rule: nonzero;
  stroke: #fff;
  stroke-width: 8.52px;
  stroke-miterlimit: 4;
}

.header {
  font-size: 20px;
}

.label {
  font-size: 16px;
}

.labelBold {
  font-size: 16px;
  font-weight: bold;
}

.labelMiddle {
  font-size: 16px;
  text-anchor: middle;
}

.labelMiddleBold {
  font-size: 16px;
  font-weight: bold;
  text-anchor: middle;
}

.labelSmallBold {
  font-size: 8px;
  font-weight: bold;
  text-anchor: middle;
}

.labelXsBold {
  font-size: 6px;
  font-weight: bold;
  text-anchor: middle;
}

.rare {
  fill: #eee;
  stroke: #eee;
}
.rareBackground {
  background-color: #eee !important;
}
.medium {
  fill: #b7ddae;
  stroke: #b7ddae;
}
.mediumBackground {
  background-color: #b7ddae !important;
}
.often {
  fill: #74bd70;
  stroke: #74bd70;
}
.oftenBackground {
  background-color: #74bd70 !important;
}
.mediumSecondary {
  fill: #a5bae1;
  stroke: #a5bae1;
}
.oftenSecondary {
  fill: #254185;
  stroke: #254185;
}

.svg {
  display: block;
  margin-bottom: 30px;
}

.group {
  column-count: 2;
  column-gap: 20px;
}

.legend {
  padding: 10px 0;
}

.legenditems {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

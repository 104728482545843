.languageSwitcher {
  max-width: 200px;
}

.label {
  color: var(--primary-color-500);
}

.label h5 {
  margin-bottom: 4px !important;
}

.buttons {
  height: 40px;
  display: flex !important;
}

.buttons button {
  padding-left: var(--fluid-button-padding) !important;
  padding-right: var(--fluid-button-padding) !important;
}

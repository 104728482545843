.cookieBanner:global(.ui.message) {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-radius: 0;
  z-index: 99999;
  padding: 1em 1.8em;
  font-size: 16px;
  display: flex;
  justify-content: space-evenly;
}

.cookieBanner a {
  color: rgba(255, 255, 255, 0.9);
  text-decoration: underline;
}

.cookieBanner a:hover {
  color: rgba(255, 255, 255, 1);
  text-decoration: underline;
}

.cookieConsentBtn:global(.ui.inverted.black.button) {
  border-radius: 0;
  margin: 0 10px;
}

.cookieConsentBtn:global(.ui.inverted.black.button):focus,
.cookieConsentBtn:global(.ui.inverted.black.button):hover {
  background-color: inherit;
  box-shadow: 0 0 0 2px #d4d4d5 inset !important;
}

.pane {
  overflow: auto hidden;
}

.link {
  background-color: white !important;
  border: 0px;
  padding: 0px;
  color: var(--primary-color-500);
  text-decoration: underline;
  cursor: pointer;
  text-align: left;
  margin-bottom: 5px;
}

.link:hover {
  color: var(--primary-color-100);
}

.relief {
  max-height: 200px;
  max-width: 100%;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.exportButton {
  background-color: var(--primary-color-700) !important;
}

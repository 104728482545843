body {
  height: 100%;
}

:global(#root) {
  height: 100%;
  overflow: hidden;
}

.wrapper {
  height: 100%;
  display: grid;
  grid-template-columns: auto min(var(--max-width-right-column), 50%);
}

.map {
  position: relative;
}

.page {
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  height: calc(100% - 73px);
}

.menu:global(.ui.menu.fluid) {
  max-width: var(--max-width-right-column);
  border-radius: 0;
  margin: 0;
  flex-shrink: 0;
  box-shadow: 0 0 20px #777;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 50% !important;
}

.item {
  border-radius: 0 !important;
  background-color: #eee !important;
  color: var(--primary-color-500) !important;
}
.item:hover {
  background-color: #f7f7f7 !important;
}
.item:global(.active.item) {
  background-color: white !important;
}

.icon {
  height: 1.5em;
  margin-bottom: 0.5em;
  fill: var(--primary-color-500);
}

.shareItem {
  border-radius: 0 !important;
  background-color: var(--primary-color-500) !important;
  color: #eee !important;
}

@media (max-width: 768px) {
  .map,
  .page:global(.frontpage) {
    display: none;
  }
  .map:global(.frontpage) {
    display: block;
  }
  .map,
  .page {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: calc(100% - 59px);
  }
  .menu:global(.ui.menu.fluid) {
    width: 100% !important;
  }
}

.map :global(.ol-hidden) {
  visibility: hidden;
}

.map :global(.ol-zoom) {
  position: absolute;
  background: none;
}

.map :global(.ol-rotate) {
  position: absolute;
  top: 105px;
}

.map :global(.ol-zoom-out) {
  padding-bottom: 4px !important;
}

.map :global(.ol-control button) {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  color: white;
  height: 42px;
  width: 42px;
  font-size: 28px;
  line-height: 38px;
  background-color: var(--primary-color-500);
  border: none;
  border-radius: 50%;
}

.map :global(.ol-scale-line) {
  right: 20px;
  left: auto;
  background: rgba(100, 100, 100, 0.8);
  bottom: 20px;
}

.map :global(.ol-control button:hover) {
  cursor: pointer;
  background-color: var(--primary-color-200);
}
.map :global(.ol-control button:focus) {
  outline: 0;
}
.map {
  height: 100vh;
}

/* Tablet */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px),
  only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .map :global(.ol-scale-line) {
    right: 70px;
  }
}

/* Mobile */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px),
  only screen and (min-width: 321px) and (max-width: 768px) {
  .map :global(.ol-scale-line) {
    right: 70px;
  }

  .map {
    height: calc(100%) !important;
  }
}

.comparisonIsSame {
  color: var(--primary-color-500);
  font-weight: bold;
}

.borderless,
.borderless td {
  border-top: none !important;
  border-bottom: none !important;
  box-shadow: none !important;
}

.treeTypeHeader {
  display: grid;
  grid-template-columns: 80% 20%;
  font-weight: bold;
}

.maximumForestTypesPopup {
  border: 2px solid var(--primary-color-200) !important;
  color: var(--primary-color-500) !important;
}

.maximumForestTypesPopup::before {
  border-top: 2px solid var(--primary-color-200) !important;
  border-left: 2px solid var(--primary-color-200) !important;
  box-shadow: none !important;
  top: -6px !important;
}

.container {
  background-color: var(--primary-color-500);
  margin: 30px 0 0 !important;
  box-shadow: 0 0 20px #777;
  flex-grow: 1;
  z-index: 1;
}
.tab :global(.ui.secondary.inverted.menu) {
  height: 90px;
}
.tabMenu :global(.item) {
  border-radius: 0 !important;
  background-color: var(--primary-color-600) !important;
  padding: 0.5em 0 !important;
  height: 100%;
  flex-direction: column;
}
.tabMenu :global(.item:first-child) {
  font-size: 1.2em;
  line-height: 1.8em !important;
  font-weight: bold !important;
}
.tabMenu :global(.item:nth-child(2)) {
  background-color: var(--primary-color-700) !important;
}
.tabMenu :global(.item:nth-child(3)) {
  background-color: var(--primary-color-800) !important;
}
.tabMenu :global(.item:nth-child(3) *) {
  z-index: 1;
}
.tabMenu :global(.item:nth-child(4)) {
  background-color: var(--primary-color-900) !important;
}
.tabMenu :global(.item:nth-child(4) *) {
  z-index: 2;
}
.tabMenu :global(.item.active) {
  background-color: var(--primary-color-500) !important;
}

.arrow:after {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-width: 1.7em;
  margin-top: -1.7em;
  z-index: 1;
}
.tabMenu :global(.item:nth-child(2):after) {
  border-left-color: var(--primary-color-700) !important;
}
.tabMenu :global(.item:nth-child(3):after) {
  border-left-color: var(--primary-color-800) !important;
}
.tabMenu :global(.item.active:after) {
  border-left-color: var(--primary-color-500) !important;
}

.tab :global(.ui.attached.segment) {
  background: none;
  color: white;
  border: none;
}

.arrow svg {
  flex-shrink: 0;
  height: 2em;
  fill: rgba(255, 255, 255, 0.7);
}

.arrow:hover svg,
.arrow:global(.active) svg {
  fill: white;
}

.altitudinalZone {
  font-weight: bold;
  font-size: 1.2em;
}

.icons {
  display: flex;
}
.icon {
  font-size: 0.8em;
  margin-right: 5px;
}
.iconToday {
  font-size: 0.8em;
  margin: 3px 5px 0 0;
}

.scenario {
  font-size: 0.8em;
  line-height: 1.5em;
}

.notFound:global(.ui.inverted.header) {
  margin: 20px;
}

.checkMapLocation:global(.ui.inverted.header) {
  font-size: 1.1em;
  line-height: 1.5em;
  font-weight: normal;
  padding: 0 20px 20px;
}

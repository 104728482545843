.icon {
  height: 3em;
}

.checkbox :global(label) {
  color: white !important;
}
.checkbox:global(.checked > label) {
  color: #fbf0b2 !important;
}

.large {
  font-size: 1.6em;
  line-height: 1.4em;
}
.medium {
  font-size: 1.3em;
  line-height: 1.4em;
}
.bold {
  font-weight: bold;
}
.yellow {
  color: #fbf0b2;
}

.sameAltitudinalZone:global(.ui.message),
.additionalInfo:global(.ui.message) {
  border: 1px solid white;
  background-color: transparent;
  color: white;
  margin: 0 1vw;
}

@media (max-width: 768px) {
  .list {
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .grid:global(.ui.stackable.grid > .column:not(.row)) {
    padding: 1rem 0 !important;
  }
}
